/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  $.fn.existsCall = function(callback) {
    var args = [].slice.call(arguments, 1);
    if (this.length) {
      callback.call(this, args);
    }
    return this;
  };

  jQuery.fn.exists = function(){return this.length>0;};

  var scrollTop     = $(window).scrollTop(),
      elementOffset = $('.banner').height();

  function navPadding() {
      if ($(window).scrollTop() > elementOffset) {
        $('.banner').addClass('pad-less');
      } else {
        $('.banner').removeClass('pad-less');
      }
  }

  function linkAnimate() {
    $(".navbar-nav li a[href^='#']").on('click', function(e) {
         e.preventDefault();

         var hash = this.hash,
             nav = $(".banner").outerHeight();

         $('html, body').animate({
             scrollTop: $(hash).offset().top - nav
           }, 500, function(){
             window.location.hash = hash;
           });
      });
  }

  function bannerMargin() {
      var bannerHeight = $('.banner').outerHeight();
      $('.navbar-margin').css('marginTop', bannerHeight);
  }

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        bannerMargin();
        navPadding();
        linkAnimate();
        $(window).scroll(function () {
            navPadding();
        });
        $(window).resize(function () {
            bannerMargin();
            navPadding();
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        $('.owl-carousel').owlCarousel({
            loop:true,
            margin:0,
            responsiveClass:true,
            responsive:{
                0:{
                    items:1,
                    nav:false,
                    dots: true
                }
            }
        });
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // Instafeed
     /* eslint-disable */


$(document).ready(function () {

  // feed settings
  var feed = new Instafeed({
      get: 'user',
      userId: 17071126073,
      accessToken: '17071126073.7c8f75c.1a9cc349ca4d49188ae1803e0dc7c4a3', 
      // target: 'instafeed',
      limit: 6, // max 60
      resolution: 'standard_resolution',
      // when each set of images are loaded... *
     
      // for each image... *
      template: '<a href="{{link}}" target="_blank" id="{{id}}" class="img-block w-50"><img src="{{image}}" /></a>',
      sortBy: 'random',
  });
  

  
  // initialize feed
  feed.run();
});

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
